import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../container/Seo/Seo';
import Layout from '../presentational/Layout/Layout';
import Banner from '../presentational/Banner/Banner';

function Imprint({ location }) {
  return (
    <Layout pathname={location.pathname}>
      <SEO title="Impressum" />
      <Banner />

      <section className="section">
        <div className="container">
          <h1>Impressum</h1>
          <div className="row">
            <div className="col col--lg-8">
              <p>
                Sauer - Catering & Ladenlokal am Sorpesee<br />
                Zum Rosengarten 3<br />
                59846 Sundern<br />
                Telefon: 0 29 35 / 25 93<br />
                <a href="mailto:info@sauer-catering.de">info@sauer-catering.de</a>
              </p>
              <p>
                Inhaber: Karl-Heinz Sauer<br />
                Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />
                DE 303 / 5097 / 1884
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

Imprint.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Imprint;
